// extracted by mini-css-extract-plugin
export var card = "StripCard-module--card--602I6";
export var chevron = "StripCard-module--chevron--8IXz3";
export var constrict = "StripCard-module--constrict--x6EbH";
export var content = "StripCard-module--content--SzAzA";
export var icon = "StripCard-module--icon--XzNNY";
export var label = "StripCard-module--label--cSVCJ";
export var link = "StripCard-module--link--RenNk";
export var rightBounce = "StripCard-module--right-bounce--QpUmX";
export var thumbnail = "StripCard-module--thumbnail--wUPeK";
export var thumbnailBounds = "StripCard-module--thumbnail-bounds--+r9VA";