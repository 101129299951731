import cx from 'classnames';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { forwardRef } from 'react';

import * as styles from './StripCard.module.css';

import Image from '@components/Molecule/Image/Image';
import ChevronRight from '@components/SVG/ChevronRight';

const Card = ({
	id,
	to,
	title,
	svg,
	thumbnail,
	alt,
	constrict,
	motionDelay = 0.02,
	children,
	className = '',
}: {
	id?: number;
	to: string;
	title: string;
	svg?: JSX.Element;
	thumbnail?: any;
	alt?: string;
	constrict?: boolean;
	motionDelay?: number;
	children: React.ReactNode;
	className?: string;
}): JSX.Element => {
	const AnimatedLink = motion(
		forwardRef((props, ref) => <Link {...props} ref={ref}></Link>)
	);

	return (
		<AnimatedLink
			to={to}
			aria-label={`Read more on ${title}`}
			className={cx(styles.link, {
				[styles.constrict]: constrict,
				[className]: !!className,
			})}
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true, amount: 0.6 }}
			transition={{
				duration: 0.35,
				delay: id ? 0.05 + motionDelay * id : 0,
			}}
			variants={{
				visible: { opacity: 1, translateY: 0 },
				hidden: { opacity: 0, translateY: '1rem' },
			}}>
			<article className={cx(styles.card)}>
				{thumbnail && (
					<div className={styles.thumbnailBounds}>
						<Image
							image={thumbnail}
							alt={alt}
							className={styles.thumbnail}
							fit="contain"
						/>
					</div>
				)}

				<div className={cx(styles.content)}>
					<div
						className={cx({
							[styles.label]: svg,
						})}>
						{svg && <div className={styles.icon}>{svg}</div>}

						{children}
					</div>

					<ChevronRight className={styles.chevron} />
				</div>
			</article>
		</AnimatedLink>
	);
};

export default Card;
