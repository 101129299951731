import cx from 'classnames';
import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';

import * as styles from './BorderImage.module.css';

import Image from '@components/Molecule/Image/Image';

const BorderImage = ({
	image,
	alt,
	large,
	as: Component = 'div',
	className = '',
}: {
	image: any;
	alt: string;
	large?: boolean;
	as?: React.ElementType;
	className?: string;
}): JSX.Element => {
	const AnimatedComponent = motion(
		forwardRef((props, ref) => <Component {...props} ref={ref}></Component>)
	);

	return (
		<AnimatedComponent
			className={cx(styles.border, {
				[styles.large]: large,
				[className]: !!className,
			})}
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true, amount: 0.4 }}
			transition={{ duration: 0.35 }}
			variants={{
				visible: { opacity: 1, translateY: 0 },
				hidden: { opacity: 0, translateY: '1rem' },
			}}>
			<Image
				image={image}
				alt={alt}
				className={styles.image}
				fit="contain"
			/>
		</AnimatedComponent>
	);
};

export default BorderImage;
