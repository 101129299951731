import cx from 'classnames';
import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';

import * as styles from './Box.module.css';

const Box = ({
	children,
	as: Component = 'div',
	className = '',
}: {
	children: React.ReactNode;
	as?: React.ElementType;
	className?: string;
}): JSX.Element => {
	const AnimatedComponent = motion(
		forwardRef((props, ref) => <Component {...props} ref={ref}></Component>)
	);

	return (
		<AnimatedComponent
			className={cx(styles.box, {
				[className]: !!className,
			})}
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true, amount: 0.6 }}
			transition={{ duration: 0.35 }}
			variants={{
				visible: { opacity: 1, translateY: 0 },
				hidden: { opacity: 0, translateY: '1rem' },
			}}>
			{children}
		</AnimatedComponent>
	);
};

export default Box;
