import { Link } from 'gatsby';
import React from 'react';

import * as styles from './Breadcrumbs.module.css';

const Breadcrumbs = ({ crumbs }: { crumbs: any }) => {
	return crumbs?.length > 0 ? (
		<div className={styles.breadcrumbs}>
			<ol className={styles.list}>
				{crumbs.map(
					(
						{
							pathname,
							crumbLabel,
						}: { pathname: string; crumbLabel: string },
						key: number
					) => {
						const label = crumbLabel.replace(/-/g, ' ');

						return key + 1 !== crumbs?.length ? (
							<li className={styles.item} key={key}>
								<Link to={pathname} className={styles.link}>
									{label}
								</Link>
								{key + 1 !== crumbs.length - 1 && (
									<span>/</span>
								)}
							</li>
						) : null;
					}
				)}
			</ol>
		</div>
	) : null;
};

export default Breadcrumbs;
