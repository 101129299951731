import React from 'react';

const ChevronRight = ({ className }: { className?: string }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 14.527 22.493">
			<path
				d="m14.031 12.434-9.562 9.563a1.681 1.681 0 0 1-2.384 0L.496 20.408a1.681 1.681 0 0 1 0-2.384l6.778-6.778L.496 4.468a1.681 1.681 0 0 1 0-2.384L2.085.495a1.681 1.681 0 0 1 2.384 0l9.562 9.562a1.672 1.672 0 0 1 0 2.377Z"
				fill="#0277bd"
			/>
		</svg>
	);
};

export default ChevronRight;
